<template>
	<div>
		<center>
			<h1>Types</h1>
		</center>
		<v-list subheader v-if="!!types">
			<v-list-item v-for="type in types" :key="type.id">
				<v-list-item-content>
					<v-list-item-title v-text="type.title" />
				</v-list-item-content>

				<v-list-item-icon>
					<v-btn fab x-small @click="edit(type)" color="info">
						<v-icon>
							mdi-pencil
						</v-icon>
					</v-btn>
				</v-list-item-icon>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<v-row align="center">
						<v-col>
							<v-text-field v-model="typeText" label="New Type" />
						</v-col>
						<v-col cols="1" align="end">
							<v-btn
								fab
								x-small
								elevation="0"
								color="primary"
								:disabled="typeText.length < 5"
							>
								<v-icon @click="add" large>
									mdi-plus
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<Alert Header="Ooooohhhhh Noooooo" v-model="showError">
			Dang it! We encountered an error. Please try again or contact support.
		</Alert>
		<Alert
			Header="Edit Type"
			v-model="showEdit"
			CloseText="Save"
			:persistent="false"
			@Close="saveEdit"
		>
			<div v-if="showEdit && editItem">
				<v-text-field v-model="editItem.title" label="Edit Type" />
			</div>
		</Alert>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Alert from "@/components/ModalMessages/Alert.vue";
export default {
	components: { Alert },
	data() {
		return {
			types: [],
			typeText: "",
			showError: false,
			showEdit: false,
			editItem: null,
		};
	},
	methods: {
		async add() {
			try {
				let r = await axios.post(
					`https://api.ticketvm.com/organization/${this.Organization.id}/types`,
					{
						title: this.typeText,
					}
				);
				this.types.push({
					id: r.data.id,
					title: this.typeText,
				});
				this.typeText = "";
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
				this.showError = true;
			}
		},
		edit(type) {
			this.editItem = type;
			this.showEdit = true;
		},
		async saveEdit() {
			try {
				await axios.patch(
					`https://api.ticketvm.com/organization/${this.Organization.id}/types/${this.editItem.id}`,
					{
						title: this.editItem.title,
					}
				);
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
				this.showError = true;
			}
			this.editItem = null;
			this.showEdit = false;
		},
	},
	computed: {
		...mapState(["Organization"]),
	},
	async mounted() {
		this.types = (
			await axios.get(
				`https://api.ticketvm.com/organization/${this.Organization.id}/types`
			)
		).data;
	},
};
</script>

<style></style>
